// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-blank-js": () => import("./../../../src/pages/de/blank.js" /* webpackChunkName: "component---src-pages-de-blank-js" */),
  "component---src-pages-de-buchung-js": () => import("./../../../src/pages/de/buchung.js" /* webpackChunkName: "component---src-pages-de-buchung-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-verfugbare-zimmer-js": () => import("./../../../src/pages/de/verfugbare-zimmer.js" /* webpackChunkName: "component---src-pages-de-verfugbare-zimmer-js" */),
  "component---src-pages-en-available-rooms-js": () => import("./../../../src/pages/en/available-rooms.js" /* webpackChunkName: "component---src-pages-en-available-rooms-js" */),
  "component---src-pages-en-blank-js": () => import("./../../../src/pages/en/blank.js" /* webpackChunkName: "component---src-pages-en-blank-js" */),
  "component---src-pages-en-booking-js": () => import("./../../../src/pages/en/booking.js" /* webpackChunkName: "component---src-pages-en-booking-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-hr-available-rooms-js": () => import("./../../../src/pages/hr/available-rooms.js" /* webpackChunkName: "component---src-pages-hr-available-rooms-js" */),
  "component---src-pages-hr-blank-js": () => import("./../../../src/pages/hr/blank.js" /* webpackChunkName: "component---src-pages-hr-blank-js" */),
  "component---src-pages-hr-booking-js": () => import("./../../../src/pages/hr/booking.js" /* webpackChunkName: "component---src-pages-hr-booking-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nabidka-pokoju-js": () => import("./../../../src/pages/nabidka-pokoju.js" /* webpackChunkName: "component---src-pages-nabidka-pokoju-js" */),
  "component---src-pages-pl-dostepne-pokoje-js": () => import("./../../../src/pages/pl/dostępne-pokoje.js" /* webpackChunkName: "component---src-pages-pl-dostepne-pokoje-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-prazdna-js": () => import("./../../../src/pages/pl/prazdna.js" /* webpackChunkName: "component---src-pages-pl-prazdna-js" */),
  "component---src-pages-pl-rezerwowac-js": () => import("./../../../src/pages/pl/rezerwowac.js" /* webpackChunkName: "component---src-pages-pl-rezerwowac-js" */),
  "component---src-pages-prazdna-js": () => import("./../../../src/pages/prazdna.js" /* webpackChunkName: "component---src-pages-prazdna-js" */),
  "component---src-pages-rezervace-js": () => import("./../../../src/pages/rezervace.js" /* webpackChunkName: "component---src-pages-rezervace-js" */)
}

